<template>
	<v-dialog v-model="show_bid_box" persistent width="auto" transition="slide-fade" fullscreen light>
		<v-row no-gutters class="bid" style="background: rgba(0,0,0,0.40);">
			<v-col cols="6" class="col-lg-6 col-md-5 col-sm-3"></v-col>
			<v-col
				cols="6"
				style="height:100vh"
				class="bid-box pa-0 d-flex flex-column col-lg-6 col-md-7 col-sm-9"
			>
				<div class="px-6 end-shadow" style="position:reletive">
					<v-row
						no-gutters
						class="pl-6 d-flex align-center"
						style="height:79px; border-bottom:1px solid #E7EAF3; margin: 0px -24px 0px -24px;"
					>
						<v-btn v-on:click="show_bid_box = false" class="my-back-btn" depressed>
							<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
							Back
						</v-btn>
					</v-row>

					<v-row no-gutters class="py-6">
						<v-col cols="6" class="d-flex align-center">
							<img
								v-if="delivery.category_id === 1"
								:src="$store.state.icons.icons['Lumber']"
								style="height:36px;"
								alt
							/>
							<img
								v-if="delivery.category_id === 2"
								:src="$store.state.icons.icons['Electric']"
								style="height:36px;"
								alt
							/>
							<img
								v-if="delivery.category_id === 3"
								:src="$store.state.icons.icons['Plumbing']"
								style="height:36px;"
								alt
							/>
							<img
								v-if="delivery.category_id === 4"
								:src="$store.state.icons.icons['Masonry']"
								style="height:36px;"
								alt
							/>
							<img
								v-if="delivery.category_id === 5"
								:src="$store.state.icons.icons['ToolBoxes']"
								style="height:36px;"
								alt
							/>

							<div class="pl-7">
								<p class="heading-mf15">{{ delivery.list_name }}</p>
								<p class="heading-rf13">List ref: {{ delivery.list_id }}</p>
							</div>
						</v-col>
						<v-col cols="6" class="d-flex align-center justify-end">
							<div
								v-if="(delivery.status === 'IP' && delivery.pickup === 'Y')"
								class="d-flex align-center justify-center mr-2"
							>
								<div class="d-flex align-center justify-center mr-4">
									<p class="heading-rf13">Mark as delivered</p>
								</div>
								<div class="d-flex justify-center align-center">
									<div v-on:click="confirmDelivery()" class="delivery-check-box"></div>
								</div>
							</div>
							<div
								v-else-if="delivery.status === 'DP'"
								class="text-tag-delivery-not text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
							>To be delivered</div>
							<div
								v-else-if="delivery.status === 'IP'"
								class="text-tag-blue d-flex align-center justify-center mr-2"
							>
								<v-btn
									class="my-p-btn my_elevation"
									id="my_elevation"
									@click="dispatch()"
									depressed
								>Dispatch</v-btn>
							</div>
							<div
								v-else-if="delivery.status === 'D'"
								class="text-tag-delivery text-tag-green d-flex align-center justify-center mr-2"
							>Delivery confirmed</div>
							<div
								v-else-if="delivery.status === 'R'"
								class="text-tag-reject text-tag-red d-flex align-center justify-center mr-2"
							>
								Situation is being reviewed
								<br />by our customer service
							</div>
							<div
								class="pl-2 pt-1"
								v-on:click="deliveryExport(delivery.delivery_id)"
								style="cursor: pointer;"
							>
								<v-tooltip left>
									<template v-slot:activator="{ on }">
										<img
											v-on="on"
											:src="$store.state.icons.icons['file-export']"
											style="height:24px;filter: invert(61%) sepia(10%) saturate(1008%) hue-rotate(178deg) brightness(84%) contrast(84%);"
											alt
										/>
									</template>
									<span>Export delivery detail</span>
								</v-tooltip>
							</div>
						</v-col>
					</v-row>

					<v-row no-gutters class="map-box">
						<v-img :src="getMapSrc()" style="width:100%;height:80px;border-radius:4px"></v-img>
					</v-row>
					<div class="d-flex align-center justify-center pb-5" style="margin-top:-60px;">
						<div class="map-btn" v-on:click="openMapRoot()">
							<img :src="$store.state.icons.icons['DeliveryVan']" alt />
							Check on map
						</div>
					</div>
				</div>

				<div class="bid-items-content pa-0 px-6 pt-6">
					<div class="show-shadow col-lg-6 col-md-7 col-sm-9"></div>

					<app-list-interaction class="mb-6" v-if="delivery.notes != null" v-model="delivery.notes"></app-list-interaction>

					<v-row no-gutters class="display-time">
						<v-col cols="4" class="pa-0 d-flex">
							<div>
								<p class="heading-rf13 pb-2">Contact Person</p>
								<p class="heading-sf15">{{ this.$options.filters.capitalize(delivery.contact_name) }}</p>
								<p class="heading-rf15">{{ phoneFormat(delivery.phone) }}</p>
							</div>
						</v-col>
						<v-col cols="4" class="pa-0 d-flex">
							<div v-if="delivery.address != null" class="pl-6">
								<p class="heading-rf13 pb-2">Address</p>
								<p class="heading-sf15">{{ firstAddress(delivery.address) }}</p>
								<p class="heading-rf15">{{ lastAddress(delivery.address) }}</p>
							</div>
						</v-col>
						<v-col cols="4" class="pa-0 d-flex">
							<div class="pl-6">
								<p class="heading-rf13 pb-2">Delivery Deadline</p>
								<p class="heading-sf15">{{ delivery.date_of_delivery }}</p>
								<p class="heading-rf15">{{ timeOfDelivery() }}</p>
							</div>
						</v-col>
					</v-row>

					<app-bid-items v-model="delivery" :listitems="listitems"></app-bid-items>

					<app-show-bid-total v-model="calculateTotal"></app-show-bid-total>
				</div>

				<div class="bid-footer delivery-bid-total d-flex flex-column mt-auto mb-6">
					<div class="bid-footer-layer"></div>
					<div class="px-6 pt-6 d-flex">
						<p class="heading-sf15">Total:</p>
						<p class="heading-sf15 ml-auto">{{ this.$options.filters.currency(total) }}</p>
					</div>
				</div>
			</v-col>
		</v-row>
		<App-map-view-root v-bind:value="mapLatLong()" ref="openmaproot"></App-map-view-root>
		<app-confirm :value="confirm" v-if="confirm.open"></app-confirm>
	</v-dialog>
</template>

<script>
const BidItems = () => import("./BidItemComponent");
const ShowBidTotal = () => import("./ShowBidTotalComponent");
const Feedback = () => import("./FeedbackComponent");
const ListInteraction = () =>
	import("../../mycomponents/ListInteractionComponent");
const MapViewRoot = () => import("./MapViewRoot");
import { Map_Key } from "../../config/variable";

export default {
	components: {
		AppBidItems: BidItems,
		AppShowBidTotal: ShowBidTotal,
		AppFeedback: Feedback,
		AppListInteraction: ListInteraction,
		AppMapViewRoot: MapViewRoot
	},
	data() {
		return {
			bid_id: "",
			show_bid_box: false,
			delivery_data: {
				check: false,
				name: "delivery",
				label: ""
			},
			confirm: {
				title: "Delivery Confirmation",
				message:
					"Are you sure this delivery material received by contractor?",
				open: false,
				confirm: false
			},
			delivery: {
				check_bid: true,
				biditems: []
			},
			listitems: [],
			bid_total: {
				sub_total: 0.0,
				shipping: 0.0,
				service_fee: 0.0,
				sales_tax: 0.0
			}
		};
	},

	computed: {
		calculateTotal() {
			this.bid_total.sub_total = 0;
			this.bid_total.service_fee = 0;
			this.bid_total.shipping = this.delivery.shipping;
			this.bid_total.sales_tax = this.delivery.sales_tax;
			this.delivery.biditems.forEach(item => {
				this.bid_total.sub_total +=
					Number(item.price) * Number(item.qty);
			});
			this.bid_total.service_fee =
				((this.bid_total.sub_total + this.bid_total.shipping) * 5) /
				100;
			this.delivery.sub_total = this.bid_total.sub_total;
			this.delivery.service_fee = this.bid_total.service_fee;
			return this.bid_total;
		},

		total() {
			let total = Number(
				this.bid_total.sub_total +
					this.bid_total.shipping +
					this.bid_total.service_fee +
					this.bid_total.sales_tax
			);
			return parseFloat(total).toFixed(2);
		}
	},

	methods: {
		getMapSrc() {
			return this.delivery.url != undefined
				? this.delivery.url["list_header"]
				: "https://the-build-static-content.s3.us-east-2.amazonaws.com/icons/map-image.png";
		},

		phoneFormat(data) {
			return data != undefined
				? this.delivery.phone.replace(
						/(\d{1,3})(\d{1,3})(\d{1,4})/g,
						"($1)-$2-$3"
				  )
				: "";
		},

		mapLatLong() {
			return {
				category_id: this.delivery.category_id,
				list_id: this.delivery.list_id,
				list_name: this.delivery.list_name,
				list_lat: this.delivery.list_lat,
				list_long: this.delivery.list_long,
				bid_lat: this.delivery.bid_lat,
				bid_long: this.delivery.bid_long,
				updated_at: this.delivery.list_updated_at,
				status: this.delivery.bid_status
			};
		},

		openMapRoot() {
			this.$refs.openmaproot.openMapRoot();
		},

		deliverySubmit(id) {
			this.$store.commit("errorMessage", "");
			this.show_bid_box = true;
			this.bid_id = id;
			if (this.list_id === "") {
				this.show_bid_box = false;
			}
			this.getDelivery();
		},

		firstAddress(address) {
			if (address) {
				let first_address = address.split(",");
				return first_address[0];
			}
		},

		lastAddress(address) {
			if (address) {
				let last_address = address.split(",");
				let full_address =
					last_address[1] != undefined ? last_address[1] : "";
				full_address =
					last_address[2] != undefined
						? full_address + ", " + last_address[2]
						: full_address;
				full_address =
					last_address[3] != undefined
						? full_address + ", " + last_address[3]
						: full_address;
				return full_address;
			}
		},

		timeOfDelivery() {
			let st = this.delivery.start_time;
			let et = this.delivery.end_time;
			if (st != undefined && et != undefined) {
				return st.substring(0, 5) + " - " + et.substring(0, 5);
			}
		},

		requiredDeliveryTime() {
			let time = this.delivery.required_delivery_time;

			if (time === "M") {
				return "Morning";
			} else if (time === "N") {
				return "Noon";
			} else if (time === "A") {
				return "Afternoon";
			} else if (time === "L") {
				return "Last Stop";
			} else if (time === "AS") {
				return "As soon as possible";
			}
		},

		async confirmDelivery() {
			if (
				window.confirm(
					"Are you sure this delivery material received by the contractor?"
				)
			) {
				// this.delivery.status = "D";
				// let ob = {
				// 	status: this.delivery.status
				// };
				// let data = await delivered(this.delivery.delivery_id, ob);
				// if (data.data) {
				// 	this.$store.commit("delivered", {
				// 		list_id: data.data.list_id,
				// 		status: data.data.status
				// 	});
				// }
				// if (data.message) {
				// 	this.show_bid_box = false;
				// }
			}
		},

		async dispatch() {
			// this.delivery.status = "DP";
			// let ob = {
			// 	status: this.delivery.status
			// };
			// let data = await delivered(this.delivery.delivery_id, ob);
			// if (data.data) {
			// 	this.$store.commit("delivered", {
			// 		list_id: data.data.list_id,
			// 		status: data.data.status
			// 	});
			// }
			// if (data.message) {
			// 	this.show_bid_box = false;
			// }
		},

		async deliveryExport(id) {
			// let data = await deliveryExport(id);
			// let url = data.data;
			// window.open(url, "_blank");
		},

		async getDelivery() {
			let data;
			// data = await getDelivery(this.bid_id);
			data = data.data;
			this.delivery.delivery_id = data.delivery_id;
			this.delivery.status = data.status;
			this.delivery.list_id = data.clist.list_id;
			this.delivery.list_name = data.clist.list_name;
			this.delivery.category_id = data.clist.category_id;
			this.delivery.contact_name = data.contractor.fullname;
			this.delivery.phone = data.contractor.phone;
			this.delivery.pickup = data.clist.pickup;
			this.delivery.address = data.clist.address;
			this.delivery.date_of_delivery = data.bid.date_of_delivery;
			this.delivery.time_of_delivery = data.bid.time_of_delivery;
			this.delivery.start_time = data.bid.start_time;
			this.delivery.end_time = data.bid.end_time;
			this.delivery.sub_total = data.bid.sub_total;
			this.delivery.shipping = data.bid.shipping;
			this.delivery.service_fee = data.bid.service_fee;
			this.delivery.sales_tax = data.bid.sales_tax;
			this.delivery.notes = data.clist.notes;
			this.delivery.biditems = data.bid.biditems;
			this.listitems = data.clist.listitems;
			this.delivery.list_lat = data.clist.latitude;
			this.delivery.list_long = data.clist.longitude;
			this.delivery.bid_lat = data.bid.latitude;
			this.delivery.bid_long = data.bid.longitude;
			this.delivery.url = data.clist.url;
		}
	}
};
</script>

<style lang="scss">
</style>